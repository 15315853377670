import React from "react";
import FED from "../../../Assets/Ostalo/Front-End Developer.jpg";
import UIDesigner from "../../../Assets/Ostalo/UX UI Designer.jpg";
import Backend from "../../../Assets/Ostalo/Back-End Developer.jpg";
import Android from "../../../Assets/Ostalo/Android Developer.jpg";
import ProgramData from "./ProgramsData";
import MainBanner from "../Home/MainBanner";

export default function Programs() {
  const programs = [
    {
      title: "UX/UI Dizajner",
      image: UIDesigner,
      price: "1000€",
      duration: [
        "1. godina ",
        "2. godina ",
        "2. godina ",
        "3. godina ",
        "3. godina ",
      ],
      skills: [
        { name: "Photoshop", icon: "fi fi-brands-photoshop" },
        { name: "Illustrator", icon: "fi fi-brands-illustrator" },
        { name: "Adobe XD", icon: "fi fi-brands-xd" },
        { name: "Figma", icon: "fi fi-brands-figma" },
        { name: "InVision", icon: "fi fi-brands-invision" },
      ],
      description:
        "Postanite stručnjak u dizajnu korisničkih sučelja i iskustava kroz intenzivnu obuku s fokusom na kreativnost i tehničke alate. Naučite raditi s profesionalnim softverima poput Photoshopa, Illustratora, Adobe XD-a i Figma kako biste izrađivali privlačne i funkcionalne dizajne. Kroz praktične projekte, savladat ćete osnove tipografije, teoriju boja i prototipiranje te steći vještine potrebne za rad u dinamičnom okruženju dizajnerskog tima.",
    },
    {
      title: "Front-end Programer",
      image: FED,
      price: "1200€",
      duration: ["1. godina ", "1. godina ", "2. godina", "3. godina"],
      skills: [
        { name: "HTML", icon: "fa-brands fa-html5" },
        { name: "CSS", icon: "fa-brands fa-css3-alt" },
        { name: "JavaScript", icon: "fa-brands fa-js" },
        { name: "React", icon: "fa-brands fa-react" },
      ],
      description:
        "Ovaj kurs vas vodi kroz sve korake izrade modernih web stranica, od osnovnih struktura do naprednih funkcionalnosti. Naučit ćete kako koristiti HTML za strukturiranje sadržaja, CSS za stiliziranje i JavaScript za dodavanje interaktivnosti. Poseban fokus je na Reactu, jednom od najpopularnijih front-end okvira, koji omogućava brzo i efikasno razvijanje složenih aplikacija. Kroz praktične projekte, steći ćete iskustvo rada s responzivnim dizajnom i savladati tehnike koje su trenutno standard u industriji.",
    },
    {
      title: "Back-end Programer",
      image: Backend,
      price: "1400€",
      duration: ["1. godina ", "2. godina ", "3. godina "],
      skills: [
        { name: "Node.js", icon: "fa-brands fa-node-js" },
        { name: "Python", icon: "fa-brands fa-python" },
        { name: "Java Script", icon: "fa-brands fa-js" },
      ],
      description:
        "Specijalizirajte se za razvoj server-side aplikacija i naučite kako raditi s bazama podataka, autentifikacijom korisnika i API integracijama. Program pokriva ključne tehnologije poput Node.js-a i Python-a, uz dubinski pregled SQL i NoSQL baza podataka, uključujući MongoDB. Kroz praktične primjere, radit ćete na aplikacijama koje uključuju korisničke prijave, upravljanje podacima i skalabilne arhitekture, pripremajući vas za profesionalni rad u razvojnim timovima.",
    },
    {
      title: "Android Programer",
      image: Android,
      price: "1300€",
      duration: ["1 godina ", "2. godina ", "3. godina "],
      skills: [
        { name: "Java", icon: "fa-brands fa-java" },
        { name: "Kotlin", icon: "fa-brands fa-kotlin" },
        { name: "Android", icon: "fa-brands fa-android" },
      ],
      description:
        "Naučite kako razvijati visokokvalitetne Android aplikacije koristeći Java i Kotlin, dva ključna jezika za mobilni razvoj. Kroz ovaj program, razumjet ćete osnovne principe Android arhitekture i naučiti koristiti različite Android biblioteke i alate za brži razvoj. Program uključuje praktične projekte poput izrade aplikacija za društvene mreže, e-trgovinu ili korisničku produktivnost, pružajući vam iskustvo potrebno za ulazak u svijet mobilnog razvoja.",
    },
  ];

  return (
    <>
      <MainBanner
        title="Veleučilište IT"
        subtitle="Pronađite svoj smjer i krenite putem uspjeha."
      />
      <div className="programs-page">
        {programs.map((program, index) => (
          <ProgramData key={index} {...program} />
        ))}
      </div>
    </>
  );
}
