import React from "react";
import AboutImg from "../../../Assets/Veleučilište/Veleučilište-about.jpg";
import { Link, useNavigate } from "react-router-dom";

export default function AboutHome() {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="about-home">
      <div className="about-home-box">
        <div>
          {" "}
          <img src={AboutImg} alt="veleučilište IT" />
          <p>
            Veleučilište Informacijske Tehnologije pruža visokokvalitetno
            obrazovanje u području IT-a, s fokusom na praktične vještine i
            najnovije tehnologije. Naša misija je osposobiti studente za
            uspješne karijere u dinamičnom svijetu IT-a.
          </p>
        </div>
        <Link className="btn" to="/o-nama" onClick={() => handleClick("/")}>
          Više &rarr;
        </Link>
      </div>
    </div>
  );
}
