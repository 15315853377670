import React from "react";
import BannerImg from "../../../Assets/Veleučilište/Veleuciliste-banner.jpg";

export default function MainBanner({ title, subtitle }) {
  return (
    <div className="main">
      <img src={BannerImg} alt="Veleučilište" />
      <div className="main-info">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}
