import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/App.scss";
import {
  About,
  Contact,
  Footer,
  Gallery,
  Home,
  Navigation,
  NotFoundPage,
  PrivacyPolicy,
  Programs,
  TermsOfUse,
} from "./Components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./Components/Partials/Errors/Error";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/o-nama" element={<About />} />
      <Route path="/programi" element={<Programs />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/galerija" element={<Gallery />} />
      {/* ERRORS */}
      <Route path="*" element={<NotFoundPage />} />
      <Route path="error" element={<ErrorPage error="Nepoznata greška" />} />
      {/* PRIVACY POLICY */}
      <Route path="/politika-privatnosti" element={<PrivacyPolicy />} />
      <Route path="/uvjeti-korištenja" element={<TermsOfUse />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
