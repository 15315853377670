import React from "react";

export default function StatsHome() {
  const stats = [
    { icon: "fa-user-graduate", number: 1200, text: "Broj studenata" },
    { icon: "fa-briefcase", number: 950, text: "Zaposleni diplomanti" },
    { icon: "fa-calendar-alt", number: 15, text: "Godine iskustva" },
    { icon: "fa-handshake", number: 30, text: "Partnerstva s IT tvrtkama" },
  ];

  return (
    <div className="stats-home">
      {stats.map((stat, index) => (
        <div key={index} className="stats-card">
          <i className={`fa ${stat.icon} stats-icon`}></i>
          <div className="stats-number">
            <p>{stat.text}</p>
            <h4>{stat.number}</h4>
          </div>
        </div>
      ))}
    </div>
  );
}
