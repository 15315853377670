import { Link } from "react-router-dom";
import { useEffect } from "react";
import MainBanner from "../Home/MainBanner";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Veleučilište IT | Politika privatnosti";
  }, []);

  return (
    <>
      <MainBanner
        title="Veleučilište IT"
        subtitle="Sigurnost vaših podataka je naš prioritet."
      />

      <div className="section privacy-terms">
        <h2>POLITIKA PRIVATNOSTI</h2>
        <p>Datum zadnje izmjene: 17.11.2024.</p>
        <p>
          Ova politika privatnosti objašnjava način na koji Veleučilište IT
          prikuplja, koristi, pohranjuje i štiti vaše osobne podatke u skladu sa
          Zakonom o zaštiti osobnih podataka Republike Hrvatske i Uredbom (EU)
          2016/679 (Opća uredba o zaštiti podataka - GDPR). Korištenjem naše web
          stranice, pristajete na obradu svojih podataka prema uvjetima ove
          politike.
        </p>
        <ul>
          <li>
            <h3>Vrsta osobnih podataka koje prikupljamo</h3>
            <p>
              Prikupljamo osobne podatke kao što su ime, e-mail adresa, IP
              adresa, podaci o preglednicima i kolačići koji se automatski
              prikupljaju prilikom vašeg posjeta našoj web stranici. Ovi podaci
              koristit će se isključivo u svrhu poboljšanja korisničkog
              iskustva.
            </p>
          </li>

          <li>
            <h3>Načini korištenja prikupljenih podataka</h3>
            <p>
              Prikupljeni podaci koriste se za poboljšanje usluga koje pružamo,
              analizu posjećenosti web stranice, personalizaciju sadržaja i
              oglasa, te u svrhu sigurnosti i zaštite web stranice.
            </p>
          </li>

          <li>
            <h3>Pohrana osobnih podataka</h3>
            <p>
              Osobni podaci bit će pohranjeni samo onoliko dugo koliko je
              potrebno za ispunjenje svrhe za koju su prikupljeni, u skladu s
              važećim zakonodavstvom.
            </p>
          </li>

          <li>
            <h3>Podjela osobnih podataka</h3>
            <p>
              Vaši osobni podaci neće biti dijeljeni s trećim stranama osim u
              slučajevima kada je to nužno za pružanje usluga ili kada je to
              zakonski zahtijevano.
            </p>
          </li>

          <li>
            <h3>Vaša prava u vezi s osobnim podacima</h3>
            <p>
              Imate pravo na pristup, ispravak, brisanje, ograničenje obrade,
              kao i pravo na prenosivost podataka. Također imate pravo uputiti
              prigovor na obradu vaših podataka ili podnijeti žalbu nadležnim
              tijelima.
            </p>
          </li>

          <li>
            <h3>Kolačići</h3>
            <p>
              Naša web stranica koristi kolačiće kako bi unaprijedila korisničko
              iskustvo i prilagodila sadržaj vašim interesima. Korištenjem
              stranice pristajete na korištenje kolačića. Ako želite, možete ih
              onemogućiti u postavkama svog preglednika.
            </p>
          </li>

          <li>
            <h3>Izmjene politike privatnosti</h3>
            <p>
              Veleučilište IT zadržava pravo izmjena ove politike privatnosti.
              Sve promjene bit će objavljene na ovoj web stranici i smatraju se
              prihvaćenima od strane korisnika s trenutkom objave.
            </p>
          </li>

          <li>
            <h3>Kontakt informacije</h3>
            <p>
              Ako imate bilo kakvih pitanja u vezi s našom politikom
              privatnosti, slobodno nas kontaktirajte putem e-maila:
              <Link className="links" to="mailto:info@veleuciliste-it.hr">
                info@veleuciliste-it.hr
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>Hvala što ste pročitali našu politiku privatnosti.</p>
      </div>
    </>
  );
}
