import React from "react";
import FED from "../../../Assets/Ostalo/Front-End Developer.jpg";
import UIDesigner from "../../../Assets/Ostalo/UX UI Designer.jpg";
import Backend from "../../../Assets/Ostalo/Back-End Developer.jpg";
import Android from "../../../Assets/Ostalo/Android Developer.jpg";
import { Link } from "react-router-dom";

export default function ProgramiHome() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <h2>NAŠI PROGRAMI</h2>
      <div className="programs">
        {/* Front-end Developer */}
        <Link
          to="/programi"
          className="programs-box"
          onClick={() => handleClick("/")}
        >
          <img src={FED} alt="Front-end Developer" />
          <div className="programs-box-info">
            <h3>Front-end Developer</h3>
            <p>
              Naučite kako izraditi moderne, responzivne i vizualno privlačne
              web stranice koristeći najnovije tehnologije poput HTML-a, CSS-a,
              JavaScript-a i React-a.
            </p>
            <div className="programs-icons">
              <i className="fa-brands fa-html5"></i>
              <i className="fa-brands fa-css3-alt"></i>
              <i className="fa-brands fa-sass"></i>
              <i className="fa-brands fa-js"></i>
              <i className="fa-brands fa-react"></i>
              <i className="fa-brands fa-cloudflare"></i>
              <i className="fa-brands fa-github"></i>
            </div>
          </div>
        </Link>
        {/* Back-end Developer */}
        <Link
          to="/programi"
          className="programs-box"
          onClick={() => handleClick("/")}
        >
          <img src={Backend} alt="Back-end Developer" />
          <div className="programs-box-info">
            <h3>Back-end Developer</h3>
            <p>
              Postanite ekspert za server-side razvoj koristeći tehnologije kao
              što su Node.js, Python, SQL i MongoDB za izgradnju moćnih i
              sigurnih aplikacija.
            </p>
            <div className="programs-icons">
              <i className="fa-brands fa-node-js"></i>
              <i className="fa-brands fa-python"></i>
              <i className="fi fi-brands-js"></i>
              <i className="fi fi-brands-java"></i>
              <i className="fa-brands fa-github"></i>
            </div>
          </div>
        </Link>
        {/* Android Developer */}
        <Link
          to="/programi"
          className="programs-box"
          onClick={() => handleClick("/")}
        >
          <img src={Android} alt="Android Developer" />
          <div className="programs-box-info">
            <h3>Android Developer</h3>
            <p>
              Postanite stručnjak za razvoj mobilnih aplikacija za Android
              platformu koristeći Java, Kotlin i moderne Android biblioteke.
            </p>
            <div className="programs-icons">
              <i className="fa-brands fa-android"></i>
              <i className="fa-brands fa-java"></i>
              <i className="fa-brands fa-github"></i>
            </div>
          </div>
        </Link>

        {/* UX/UI Designer */}
        <Link
          to="/programi"
          className="programs-box"
          onClick={() => handleClick("/")}
        >
          <img src={UIDesigner} alt="UX UI Designer" />
          <div className="programs-box-info">
            <h3>UX/UI Designer</h3>
            <p>
              Razvijajte svoje vještine u dizajniranju korisničkog iskustva i
              interfejsa, fokusirajući se na estetiku i funkcionalnost.
            </p>
            <div className="programs-icons">
              <i className="fa-brands fa-sketch"></i>
              <i className="fa-brands fa-adobe"></i>
              <i className="fa-brands fa-figma"></i>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
