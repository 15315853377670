import { Link } from "react-router-dom";
import ITLogo from "../../../Assets/Veleučilište/Veleuciliste-logo.png";

export default function Footer() {
  let year = new Date().getFullYear();

  // Scroll to top of the page
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <div className="footer-top">
        {" "}
        <img src={ITLogo} alt="NK Podsused" />
        <div className="footer-container">
          <h3>VELEUČILIŠTE IT</h3>
          <ul>
            <li>
              <Link
                className="footer-links"
                to="/o-nama"
                onClick={() => handleClick("/")}
              >
                O NAMA
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/programi"
                onClick={() => handleClick("/")}
              >
                PROGRAMI
              </Link>
            </li>
            {/* 
            <li>
              <Link
                className="footer-links"
                to="/cjenik"
                onClick={() => handleClick("/")}
              >
                CJENIK
              </Link>
            </li> */}

            <li>
              <Link
                className="footer-links"
                to="/galerija"
                onClick={() => handleClick("/")}
              >
                {" "}
                GALERIJA
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/politika-privatnosti"
                onClick={() => handleClick("/")}
              >
                Politika Privatnosti
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/uvjeti-korištenja"
                onClick={() => handleClick("/")}
              >
                Uvjeti Korištenja
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-container">
          <h3>OSTALO</h3>
          <ul>
            <li>
              <Link className="footer-links" to="/">
                <i className="fi fi-rs-marker"></i>Veleučilišna ulica . 58,
                10000, Zagreb
              </Link>
            </li>
            <li>
              <a href="/" className="footer-links">
                <i className="fi fi-rs-at"></i>info@veleuciliste-it.com
              </a>
            </li>
            <li>
              <a href="/" className="footer-links">
                <i className="fi fi-rs-phone-flip"></i> +385 099 533 3363
              </a>
            </li>
            <li>
              <a href="/" className="footer-links">
                <i className="fi fi-rs-phone-flip"></i> +01 1234 567
              </a>
            </li>
            <li>
              <span className="footer-links">
                <i className="fi fi-rs-bank"></i>IBAN: HR1234567890123456789
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-container">
        <Link className="footer-social-links" to="/" target="_blank">
          <i className="fi fi-brands-instagram"></i>
        </Link>
      </div>
      <div className="footer-bottom">
        <h6>
          &copy; <span>{year}</span> Veleučilište IT
        </h6>
        <h5>Web izrada: Teo Ivanuš</h5>
      </div>
    </footer>
  );
}
