import React, { useEffect } from "react";
import MainBanner from "./MainBanner";
import ProgramiHome from "./ProgramiHome";
import AboutHome from "./AboutHome";
import Reviews from "./Reviews";
import StatsHome from "./StatsHome";
import GalleryHome from "./GalleryHome";

const Home = () => {
  useEffect(() => {
    document.title = "Veleučilište IT";
  }, []);

  return (
    <>
      <MainBanner
        title="Veleučilište IT"
        subtitle="Pronađite svoj smjer i krenite putem uspjeha."
      />
      <div className="section">
        <h2>O NAMA</h2>
        <AboutHome />
        <ProgramiHome />
        <Reviews />
        <h2>NAŠI REZULTATI</h2>
        <StatsHome />
        <GalleryHome />
      </div>
    </>
  );
};

export default Home;
