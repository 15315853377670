import React, { useState } from "react";
import Image1 from "../../../Assets/Galerija/Gallery1.jpg";
import Image2 from "../../../Assets/Galerija/Gallery2.jpg";
import Image3 from "../../../Assets/Galerija/Gallery3.jpg";
import Image4 from "../../../Assets/Galerija/Gallery4.jpg";
import Image5 from "../../../Assets/Galerija/Gallery5.jpg";
import Image6 from "../../../Assets/Galerija/Gallery6.jpg";
import MainBanner from "../Home/MainBanner";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  const openImage = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentIndex(null);
  };

  const goToPrevious = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    }
  };

  const goToNext = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <>
      <MainBanner title="Veleučilište IT" subtitle="Pogledajte naše slike." />
      <div className="section">
        <h2>GALERIJA</h2>
        <div className="image-grid">
          {images.map((image, index) => (
            <div
              key={index}
              className="gallery-item"
              onClick={() => openImage(index)}
            >
              <img src={image} alt={`Slika ${index + 1}`} />
            </div>
          ))}
        </div>

        {/* Modal za pregled slike */}
        {isModalOpen && (
          <div className="modal" onClick={closeModal}>
            <button
              className="prev-button"
              onClick={(e) => {
                e.stopPropagation();
                goToPrevious();
              }}
            >
              &#10094;
            </button>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button
                className="close-button"
                onClick={(e) => {
                  e.stopPropagation();
                  closeModal();
                }}
              >
                &times;
              </button>
              <img
                src={images[currentIndex]}
                alt={`Slika ${currentIndex + 1}`}
              />
            </div>
            <button
              className="next-button"
              onClick={(e) => {
                e.stopPropagation();
                goToNext();
              }}
            >
              &#10095;
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
