import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ITLogo from "../../../Assets/Veleučilište/Veleuciliste-logo.png";

export default function Navigation() {
  const [show, setShow] = useState(false); // Controls the mobile menu
  const [showSubmenu, setShowSubmenu] = useState(false); // Controls submenu visibility for "PROGRAMI"
  const navigate = useNavigate();

  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "transparent",
  });

  // Zatvori Menu kad se otvori neka stranica
  const closeMenu = () => {
    setShow(false);
  };

  // Otvori submenu programi
  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  // Zatvori submenu programi
  const closeSubmenu = () => {
    setShowSubmenu(false);
  };

  // Na klik otiđi skroz gore (smooth)
  const handleClick = (path) => {
    closeMenu();
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Promjeni boju navigacije kad se skrolla ispod visine 0.25vh iz prozirne u linear-gradient
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY / window.innerHeight;
      if (scrolled >= 0.25) {
        setNavbarStyle({
          background: "linear-gradient(#304770f6, #304770ea)",
        });
      } else {
        setNavbarStyle({
          backgroundColor: "transparent",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav id="navigation" style={navbarStyle}>
      <div
        className={show ? "hamburger active" : "hamburger"}
        onClick={() => setShow(!show)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <div>
        <ul className={show ? "nav-links active" : "nav-links"}>
          <img className="navbar-logo" src={ITLogo} alt="VIT" />
          <li>
            <Link
              className="navbar-links"
              to="/"
              onClick={() => handleClick("/")}
            >
              NASLOVNA
            </Link>
          </li>
          <li>
            <Link
              className="navbar-links"
              to="/o-nama"
              onClick={() => handleClick("/o-klubu")}
            >
              O NAMA
            </Link>
          </li>

          <li>
            <span className="navbar-links" onClick={toggleSubmenu}>
              PROGRAMI <i className="fi fi-rs-caret-down"></i>
              {showSubmenu && (
                <ul className="submenu">
                  <li>
                    <Link
                      className="submenu-links"
                      to="/programi"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/grupni");
                      }}
                    >
                      FRONT-END <span>developer</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/programi"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/individualni");
                      }}
                    >
                      BACK-END <span>developer</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/programi"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/individualni");
                      }}
                    >
                      ANDROID <span>developer</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/programi"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/individualni");
                      }}
                    >
                      UX/UI <span>designer</span>
                    </Link>
                  </li>
                </ul>
              )}
            </span>
          </li>

          <li>
            <Link
              className="navbar-links"
              to="/kontakt"
              onClick={() => handleClick("/kontakt")}
            >
              KONTAKT
            </Link>
          </li>

          <div className="social-links">
            <Link to="/">
              <i className="fi fi-brands-instagram"></i>
            </Link>
          </div>
        </ul>
      </div>
      <Link to="/" onClick={() => handleClick("/")}>
        <img className="navigation-logo" src={ITLogo} alt="VIT" />
      </Link>
    </nav>
  );
}
