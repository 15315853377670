import React from "react";

export default function ProgramData({
  title,
  image,
  price,
  duration,
  skills,
  description,
}) {
  return (
    <div className="program-info">
      <div className="program-top">
        <div className="program-img">
          <img src={image} alt={title} />
          <div>
            <h2>{title}</h2>
            <span>{price}</span>
          </div>
        </div>

        <div className="programs-grid">
          <div className="programs-box">
            {skills.map((skill, index) => (
              <p key={index}>
                <i className={skill.icon}></i>
                {skill.name}
              </p>
            ))}

            <p>
              <i className="fa-solid fa-certificate"></i>Završni ispit
            </p>
          </div>
          <div className="programs-box">
            {duration.map((time, index) => (
              <p key={index}>{time}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="programs-bottom">
        <form action="#" className="form">
          <label htmlFor="city">Odaberi lokaciju:</label>
          <select name="course" id="city">
            <option value="/">Veleučilišna ulica 58, Zagreb</option>
            <option value="/">Online</option>
          </select>
        </form>
        <a className="programs-btn" href="/">
          PRIJAVI SE
        </a>
        <h4>Što ćete naučiti?</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
