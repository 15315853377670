import React, { useState } from "react";

import MainBanner from "../Home/MainBanner";

const KontaktKomponenta = () => {
  const [faq, setFaq] = useState({});

  const toggleFaq = (index) => {
    setFaq((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const faqPitanja = [
    {
      pitanje: "Kako se održava nastava?",
      odgovor: "Nastava se održava uživo i online, ovisno o programu.",
    },
    {
      pitanje: "Koji su uvjeti upisa?",
      odgovor:
        "Uvjeti upisa uključuju srednjoškolsku diplomu i prijemni ispit.",
    },
    {
      pitanje: "Postoje li mogućnosti stipendija?",
      odgovor:
        "Da, nudimo stipendije za izvrsne studente i socijalne kategorije.",
    },
    {
      pitanje: "Kako mogu kontaktirati administraciju?",
      odgovor: "Možete nas kontaktirati putem e-maila ili telefonski.",
    },
    {
      pitanje: "Koji su načini plaćanja školarine?",
      odgovor:
        "Plaćanje se može izvršiti jednokratno ili u ratama, putem bankovnog računa.",
    },
    {
      pitanje: "Koliko traju studijski programi?",
      odgovor: "Programi traju 3 godine za preddiplomski studij.",
    },
    {
      pitanje: "Postoji li praksa za studente?",
      odgovor: "Da, praksa je obavezna i dio je svakog programa.",
    },
    {
      pitanje: "Gdje mogu pronaći raspored predavanja?",
      odgovor: "Raspored predavanja dostupan je na portalu za studente.",
    },
    {
      pitanje: "Kako funkcionira mentorski sustav?",
      odgovor:
        "Svaki student ima mentora koji prati njegov napredak tijekom studija.",
    },
    {
      pitanje: "Koje jezike podržava nastava?",
      odgovor:
        "Nastava se održava na hrvatskom i engleskom jeziku, ovisno o programu.",
    },
  ];

  return (
    <>
      <MainBanner title="Veleučilište IT" subtitle="Kontaktirajte nas." />
      <div className="section">
        <h2>KONTAKT</h2>
        <ul className="contact-links">
          <li>
            <a href="/">
              {" "}
              <i className="fi fi-rs-marker"></i>{" "}
              <p>Veleučilišna ulica 58, 10000, Zagreb</p>
            </a>
          </li>
          <li>
            <a href="mailto:info@veleuciliste-it.com" className="footer-links">
              <i className="fi fi-rs-at"></i> <p>info@veleuciliste-it.com</p>
            </a>
          </li>
          <li>
            <a href="tel:/" className="footer-links">
              <i className="fi fi-rs-phone-flip"></i>
              <p> +385 099 123 4567</p>
            </a>
          </li>
          <li>
            <a href="tel:/" className="footer-links">
              <i className="fi fi-rs-phone-flip"></i>
              <p> +01 1234 567</p>
            </a>
          </li>
          <li>
            <i className="fi fi-rs-bank"></i> <p>IBAN: HR1234567890123456789</p>
          </li>
          <li>
            <i className="fi fi-rs-clock"></i> <h5>Radno vrijeme:</h5>
            <p>
              {" "}
              <span>Pon-Pet </span>9:00 - 17:00
            </p>
          </li>
        </ul>

        <h2>Česta pitanja (FAQ)</h2>
        <div className="faq-box">
          {faqPitanja.map((item, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFaq(index)}>
                <h4>
                  {item.pitanje}{" "}
                  <i
                    className={`fi ${
                      faq[index]
                        ? "fi-rs-angle-small-up"
                        : "fi-rs-angle-small-down"
                    }`}
                  ></i>
                </h4>
              </div>
              {faq[index] && <div className="faq-answer">{item.odgovor}</div>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default KontaktKomponenta;
