import React from "react";

import Ivan from "../../../Assets/Recenzije/Ivan.jpg";
import Petra from "../../../Assets/Recenzije/Petra.jpg";
import Marta from "../../../Assets/Recenzije/Marta.jpg";

const reviewsData = [
  {
    name: "Ivan M.",
    image: Ivan,
    rating: "★ ★ ★ ★ ★",
    reviewText:
      "Izvrsno veleučilište s modernim pristupom učenju i vrhunskim profesorima. Praktični rad i aktualne tehnologije su veliki plus!",
  },
  {
    name: "Petra K.",
    image: Petra,
    rating: "★ ★ ★ ★",
    reviewText:
      "Kvalitetan program s puno praktičnih zadataka. Malo je naporno tijekom ispitnih rokova, ali trud se isplati!",
  },
  {
    name: "Marta P.",
    image: Marta,
    rating: "★ ★ ★ ★ ★",
    reviewText:
      "Profesori su stručni i uvijek spremni pomoći. Nastava je zanimljiva, a infrastruktura suvremena. Preporučujem svima zainteresiranima za IT!",
  },
];

export default function Reviews() {
  return (
    <>
      <div className="reviews-container">
        <h3>ISKUSTVA STUDENATA</h3>
        <div className="reviews-grid">
          {reviewsData.map((review, index) => (
            <div className="review-card" key={index}>
              {" "}
              <img
                src={review.image}
                alt={review.name}
                className="review-image"
              />{" "}
              <h4>{review.name}</h4>
              <div className="review-rating">{review.rating}</div>
              <p>{review.reviewText}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
