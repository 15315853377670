import React, { useRef } from "react";
import Gallery1 from "../../../Assets/Galerija/Gallery1.jpg";
import Gallery2 from "../../../Assets/Galerija/Gallery2.jpg";
import Gallery3 from "../../../Assets/Galerija/Gallery3.jpg";
import Gallery4 from "../../../Assets/Galerija/Gallery4.jpg";
import Gallery5 from "../../../Assets/Galerija/Gallery5.jpg";
import Gallery6 from "../../../Assets/Galerija/Gallery6.jpg";

/* import { Link, useNavigate } from "react-router-dom"; */

const images = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6];

const GalleryHome = () => {
  const marqueeRef = useRef(null);

  /*const navigate = useNavigate();
   const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  }; */

  return (
    <div className="gallery-home">
      {/*     <Link
        className="btn"
        onClick={() => handleClick("/galerija")}
        to="/galerija"
      >
        Galerija &rarr;
      </Link> */}
      <div className="slider-gallery-container">
        <div className="slider-gallery" ref={marqueeRef}>
          {images.map((image, index) => (
            <img key={index} src={image} alt={`Gallery ${index + 1}`} />
          ))}
          {images.map((image, index) => (
            <img
              key={`clone-${index}`}
              src={image}
              alt={`Clone ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryHome;
