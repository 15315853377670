import React from "react";
import { Link } from "react-router-dom";
// Images
import ITLogo from "../../../Assets/Veleučilište/Veleuciliste-logo.png";

export default function ErrorPage({ error, hideFooter, hideSponsors }) {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="section error-page">
        <div>
          <img src={ITLogo} alt="Veleučilište IT" />
          <h2>DOŠLO JE DO GREŠKE!</h2>
          <p>{error}!</p>
          <p>
            Pokušajte kasnije ili nas kontaktirajte na mail{" "}
            <a href="mailto:/" className="footer-links">
              <i className="fi fi-rs-at"></i>info@veleuciliste-it.com
            </a>
            .
          </p>

          <Link className="btn" to="/" onClick={() => handleClick("/")}>
            NATRAG NA NASLOVNU
          </Link>
        </div>
      </div>
    </>
  );
}
