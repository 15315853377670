import { Link } from "react-router-dom";
import { useEffect } from "react";
import MainBanner from "../Home/MainBanner";

export default function TermsOfUse() {
  useEffect(() => {
    document.title = "Veleučilište IT | Uvjeti korištenja";
  }, []);

  return (
    <>
      <MainBanner title="Veleučilište IT" subtitle="Uvjeti korištenja" />

      <div className="section privacy-terms">
        <h2>UVJETI KORIŠTENJA</h2>
        <p>Datum zadnje izmjene: 17.11.2024.</p>
        <p>
          Ovi uvjeti korištenja reguliraju pristup i korištenje web stranice
          Veleučilišta IT. Molimo vas da pažljivo pročitate ove uvjete prije
          nego što pristupite ili koristite našu web stranicu. Korištenjem naše
          web stranice, potvrđujete da ste pročitali, razumjeli i pristali na
          sve uvjete i odredbe navedene u ovom dokumentu.
        </p>
        <ul>
          <li>
            <h3>Opće odredbe</h3>
            <p>
              Korištenje web stranice Veleučilišta IT podliježe svim važećim
              zakonima Republike Hrvatske i EU. Korisnici su obvezni koristiti
              ovu web stranicu u skladu s tim zakonima i propisima, te se
              suzdržavati od bilo kakvih aktivnosti koje mogu štetiti
              funkcionalnosti stranice, sigurnosti ili povjeri podataka drugih
              korisnika.
            </p>
          </li>

          <li>
            <h3>Intelektualno vlasništvo</h3>
            <p>
              Svi sadržaji objavljeni na našoj web stranici, uključujući
              tekstove, slike, grafike, logotipe i druge materijale, zaštićeni
              su autorskim pravima i zakonima o intelektualnom vlasništvu.
              Reprodukcija, distribucija ili javna izlaganja tih materijala
              dopuštena je samo uz prethodno pismeno odobrenje Veleučilišta IT.
            </p>
          </li>

          <li>
            <h3>Izmjene uvjeta korištenja</h3>
            <p>
              Veleučilište IT zadržava pravo izmjene ili ažuriranja ovih uvjeta
              korištenja. Sve promjene bit će objavljene na ovoj web stranici i
              smatraju se prihvaćenima od strane korisnika s trenutkom objave.
            </p>
          </li>

          <li>
            <h3>Ograničenje odgovornosti</h3>
            <p>
              Iako se trudimo osigurati točnost i ažurnost svih informacija na
              našoj web stranici, Veleučilište IT ne jamči potpunu točnost,
              pouzdanost ili valjanost tih informacija. Korištenje sadržaja sa
              stranice korisnici obavljaju na vlastitu odgovornost.
            </p>
          </li>

          <li>
            <h3>Kontakt informacije</h3>
            <p>
              Za dodatna pitanja u vezi s uvjetima korištenja naše web stranice,
              slobodno nas kontaktirajte putem e-maila:
              <Link className="links" to="mailto:info@veleuciliste-it.hr">
                info@veleuciliste-it.hr
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>Hvala što ste pročitali naše uvjete korištenja.</p>
      </div>
    </>
  );
}
