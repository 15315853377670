import React, { useEffect } from "react";
import AnaV from "../../../Assets/Zaposlenici/AnaV.jpg";
import MarioB from "../../../Assets/Zaposlenici/MarioB.jpg";
import KatarinaA from "../../../Assets/Zaposlenici/KatarinaA.jpg";
import AndreaS from "../../../Assets/Zaposlenici/AndreaŠ.jpg";
import PetarK from "../../../Assets/Zaposlenici/PetarK.jpg";
import Veleuciliste1 from "../../../Assets/Veleučilište/Veleuciliste1.jpg";

import MainBanner from "../Home/MainBanner";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Veleučilište IT | O nama";
  }, []);

  const staffMembers = [
    {
      name: "Ana V.",
      role: "Dekan",
      date: "06.11.1985",
      cv: "Ana V. ima više od 15 godina iskustva u programiranju i obrazovanju. Specijalizirala se za razvoj web aplikacija koristeći moderne tehnologije poput Reacta i Vue.js. Njezina strast su inovacije u obrazovanju.",
      image: AnaV,
    },
    {
      name: "Andrea Š.",
      date: "03.08.1990",
      role: "UX/UI Designer, voditeljica računovodstva",
      cv: "Andrea Š. ima bogato iskustvo u grafičkom i interaktivnom dizajnu. Radila je na projektima za međunarodne klijente, a uz to vodi i odjel računovodstva na veleučilištu.",
      image: AndreaS,
    },
    {
      name: "Katarina A.",
      date: "26.09.1984",
      role: "Back-end developer",
      cv: "Katarina A. je stručnjakinja za vizualne komunikacije s više od 10 godina iskustva. Njezin rad obuhvaća dizajn brendova, digitalni marketing i mentoriranje studenata.",
      image: KatarinaA,
    },
    {
      name: "Mario B.",
      date: "30.01.1989",
      role: "Android Developer",
      cv: "Mario B. je certificirani Android developer s iskustvom u razvoju mobilnih aplikacija koristeći Java i Kotlin. Radio je na nizu aplikacija koje se koriste širom svijeta.",
      image: MarioB,
    },
    {
      name: "Petar K.",
      date: "14.04.1987",
      role: "Front-end Developer",
      cv: "Petar K. je iskusni softverski inženjer koji je radio u vodećim IT tvrtkama. Specijalizirao se za JavaScript okvire i tehnike razvoja responzivnih web aplikacija.",
      image: PetarK,
    },
  ];

  return (
    <>
      <MainBanner
        title="Veleučilište IT"
        subtitle="Upoznajte našu viziju, misiju i povijest."
      />
      <div className="section">
        <h2>O NAMA</h2>
        <div className="about">
          <div className="about-grid">
            <img src={Veleuciliste1} alt="Veleučilište IT" />
            <p>
              Veleučilište Informacijske Tehnologije (Veleučilište IT) osnovano
              je s ciljem pružanja vrhunskog obrazovanja u području
              informacijske tehnologije. Od svog osnutka, veleučilište se
              pozicioniralo kao lider u IT edukaciji, kombinirajući teorijske
              osnove s praktičnim znanjima. S ponosom ističemo našu predanost
              inovacijama, naprednom istraživanju i pripremi studenata za
              uspješne karijere u dinamičnom IT sektoru.
            </p>
          </div>
          <h3>Povijest</h3>
          <p>
            Veleučilište IT osnovano je 2000. godine s ciljem stvaranja
            obrazovne institucije koja spaja akademsku izvrsnost i praktična
            znanja u području informacijske tehnologije. Kroz godine, razvili
            smo raznovrsne programe usmjerene na suvremene tehnologije i
            industrijske potrebe, te ostvarili brojna partnerstva s vodećim IT
            tvrtkama.
          </p>

          <div className="mission-vision-content">
            <div>
              <i className="fi fi-ts-vision"></i>
              <h3>Vizija</h3>
              <p>
                Postati vodeće veleučilište u regiji koje obrazuje vrhunske
                stručnjake u IT sektoru, doprinosi inovacijama i transformira
                društvo kroz tehnologiju.
              </p>
            </div>
            <div>
              <i className="fi fi-ts-bullseye-arrow"></i>
              <h3>Misija</h3>
              <p>
                Pružiti visokokvalitetno obrazovanje koje kombinira teoriju i
                praksu, razvija vještine kritičkog razmišljanja i prilagođava se
                potrebama modernog tržišta rada. Naš cilj je osposobiti studente
                za stvaranje tehnoloških rješenja koja mijenjaju svijet.
              </p>
            </div>
          </div>
          <div>
            {" "}
            <i class="fi fi-ts-employees"></i>
            <h3>Naš Tim</h3>
            <p>
              Naše veleučilište okuplja iskusne profesore, IT stručnjake i
              istraživače koji su posvećeni mentoriranju i inspiriranju
              studenata. Svi naši zaposlenici kontinuirano rade na svom
              profesionalnom razvoju kako bi pratili najnovije trendove u
              industriji i prenijeli ih studentima.
            </p>
          </div>
          <div className="staff">
            {staffMembers.map((member, index) => (
              <div key={index} className="staff-member">
                <img src={member.image} alt={member.name} />

                <h3>{member.name}</h3>

                <h6>{member.role}</h6>

                <p> {member.cv}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
